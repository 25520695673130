<template>
  <div v-if="isModalOpen" class="modal fade show d-block" id="exampleModalCenter" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="background: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog modal-dialog-centered" role="document" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-content" style="background: transparent !important; border: none; width: fit-content;">
        <div class="modal-body p-0" style="background: transparent;">
          <button type="button" class="close modal_btn d-flex justify-content-end position-absolute" @click="closeModal"
                  aria-label="Close" style="top: 1%; left: 93%;">
            <span aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                <path
                    d="M7.31382 5.89982L10.8488 2.36382C10.9443 2.27157 11.0205 2.16123 11.0729 2.03922C11.1253 1.91722 11.1529 1.786 11.1541 1.65322C11.1552 1.52044 11.1299 1.38876 11.0796 1.26587C11.0294 1.14297 10.9551 1.03132 10.8612 0.937425C10.7673 0.843532 10.6557 0.769279 10.5328 0.718998C10.4099 0.668717 10.2782 0.643415 10.1454 0.644569C10.0126 0.645723 9.88142 0.673309 9.75942 0.725718C9.63741 0.778127 9.52707 0.854309 9.43482 0.949819L5.89882 4.48482L2.36382 0.949819C2.27157 0.854309 2.16123 0.778127 2.03922 0.725718C1.91722 0.673309 1.786 0.645723 1.65322 0.644569C1.52044 0.643415 1.38876 0.668717 1.26587 0.718998C1.14297 0.769279 1.03132 0.843532 0.937425 0.937425C0.843532 1.03132 0.769279 1.14297 0.718998 1.26587C0.668717 1.38876 0.643415 1.52044 0.644569 1.65322C0.645723 1.786 0.673309 1.91722 0.725718 2.03922C0.778127 2.16123 0.854309 2.27157 0.949819 2.36382L4.48482 5.89882L0.949819 9.43482C0.854309 9.52707 0.778127 9.63741 0.725718 9.75942C0.673309 9.88142 0.645723 10.0126 0.644569 10.1454C0.643415 10.2782 0.668717 10.4099 0.718998 10.5328C0.769279 10.6557 0.843532 10.7673 0.937425 10.8612C1.03132 10.9551 1.14297 11.0294 1.26587 11.0796C1.38876 11.1299 1.52044 11.1552 1.65322 11.1541C1.786 11.1529 1.91722 11.1253 2.03922 11.0729C2.16123 11.0205 2.27157 10.9443 2.36382 10.8488L5.89882 7.31382L9.43482 10.8488C9.52707 10.9443 9.63741 11.0205 9.75942 11.0729C9.88142 11.1253 10.0126 11.1529 10.1454 11.1541C10.2782 11.1552 10.4099 11.1299 10.5328 11.0796C10.6557 11.0294 10.7673 10.9551 10.8612 10.8612C10.9551 10.7673 11.0294 10.6557 11.0796 10.5328C11.1299 10.4099 11.1552 10.2782 11.1541 10.1454C11.1529 10.0126 11.1253 9.88142 11.0729 9.75942C11.0205 9.63741 10.9443 9.52707 10.8488 9.43482L7.31382 5.89882V5.89982Z"
                    fill="#f99f00"/>
              </svg>
            </span>
          </button>
          <img class="img-fluid rounded-5" src="./home/keebabi10.png"
               alt="Use KEEBABI10 to get 10% discount" style="width: 100%; max-width: 38rem;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaticBackdropModal',
  data() {
    return {
      isModalOpen: false // Modal initially closed
    };
  },
  methods: {
    closeModal() {
      this.isModalOpen = false; // Close the modal
      document.body.style.overflow = ''; // Re-enable scrolling

      // Set localStorage to remember that the modal was viewed
      const viewedDate = new Date();
      localStorage.setItem('modalViewedDate', viewedDate.toISOString());
    }
  },
  mounted() {
    // Check localStorage to see if the modal has been viewed in the past month
    const modalViewedDate = localStorage.getItem('modalViewedDate');
    if (modalViewedDate) {
      const currentDate = new Date();
      const viewedDate = new Date(modalViewedDate);

      // Calculate the difference in months between the current date and the viewed date
      const diffMonths = currentDate.getMonth() - viewedDate.getMonth() +
          (12 * (currentDate.getFullYear() - viewedDate.getFullYear()));

      // Show the modal only if it hasn't been viewed in the past month
      if (diffMonths >= 1) {
        this.isModalOpen = true;
        document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
      }
    } else {
      // If there's no record of viewing, show the modal
      this.isModalOpen = true;
      document.body.style.overflow = 'hidden'; // Disable scrolling
    }
  }
}
</script>

<style scoped>
.modal-backdrop.show {
  opacity: 0.5 !important;
}

.modal_btn {
  top: -2% !important;
  left: 90% !important;
  width: 0%;
  color: white;
  border: none;
  border-radius: 15px;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s;
}

</style>
