<template>
  <div>
    <div class="our-belifes position-relative">

      <img class="our-belifes-bg-one" src="./Our-belifes-image/Our-belifes-bg-one.png" alt="">
      <img class="our-belifes-header-bg" src="./Our-belifes-image/Keebabi.gif" alt="">
      <div class="our-belifes-text">
        <h1>"At Keebabi™️, our journey through two decades in the food industry has honed our commitment to delivering
          exceptional experiences. We take pride in crafting a unique experience that blends freshness, flavor, and
          tradition™️, offering a delightful twist on Iranian/Persian cuisine tailored to your taste. "<br>
        </h1>
      </div>
      <div class="our-belifes-icons ">

        <img class="our-belifes-icons-one position-absolute " src="./our-beliefs-converted/Vector.png"
             alt="">

        <img class="our-belifes-icons-two position-absolute " src="./our-beliefs-converted/Vector2.png"
             alt="">

      </div>
    </div>


    <div class="our-belifes-gif ">
      <video class="w-100" autoplay loop muted playsinline load>
        <source src="./our-beliefs-converted/Gif2.webm" type="video/webm">
        Your browser does not support the video tag.
      </video>
    </div>


    <div class="our-belifes-commitment ">
      <img class="w-100" src="./Our-belifes-image/Our-belifes-commitment-bg.png" alt="">
      <div class="our-belifes-commitment-content d-flex align-items-center">
        <div class="ourbelifes-commitment-left">


          <img class="ourbelifes-bottom-img" src="./Our-belifes-image/our-belifes-animation-dots.png" alt="">
          <img class="ourbelifes-top-img" src="./our-beliefs-converted/Our-beliefs.webp" alt="">

        </div>
        <div class="our-belifes-commitment-right ">
          <h1>Our Commitment</h1>
          <p>We take pride in crafting a unique experience that blends freshness, flavor, and tradition™️.Our dedication
            to exceptional dining ensures every meal is tailored to your taste. Discover how we combine the best
            ingredients and culinary expertise to create delicious, memorable dishes just for you.<br> Quality is
            paramount
            in our kitchen. While some dishes may require specific preparation methods, such as
            freezing meat for Koobideh to enhance taste and texture, we never compromise on overall freshness by using
            canned ingredients. By avoiding shortcuts, we uphold the true essence of traditional Iranian cuisine,
            delivering dishes that are made with care and attention to detail. </p>
        </div>
      </div>
    </div>


    <div class="our-belifes-twist position-relative w-100 h-100">


      <div class="our-belifes-bg">
        <div class="our-belifes-content d-flex align-items-center justify-content-between">
          <div class="our-belifes-content-left">
            <h1>Tradition with a Twist</h1>
            <p>We offer a delicious twist on Iranian/Persian cuisine, blending traditional recipes with modern flavors.
              Explore the rich heritage and creativity that make our menu unique and exciting. </p>
            <p>
              At place, we believe that dining is not just about food, but also about the overall experience. Our staff,
              renowned for their warmth and dedication, strives to make every visit an unforgettable event.
            </p>
          </div>
          <div class="our-belifes-content-right">
            <img src="./our-beliefs-converted/Our-beliefs-2.webp" alt="">
          </div>

        </div>
        <div class="our-belifes-gif-two">
          <div class="plate-img position-absolute">

            <img src="./Our-belifes-image/our-belifes-plate-animation.png" alt="">
          </div>
          <img src="./Our-belifes-image/our-belifes-gif-two.gif" alt="">
        </div>
      </div>


      <div class="top-quality">
        <div class="top-quality-bg">
          <div class="top-quality-animation">
            <img src="./Our-belifes-image/top-quality-animation.gif" alt="">
            <div class="top-small-img">
            </div>
          </div>
        </div>
      </div>

      <div class="your-enjoyment z-1">
        <div class="your-enjoyment-content p-3">
          <p class="text-white d-none">
            <br> We believe in the purity of natural
            ingredients. That's why we never use artificial flavors, colors, or preservatives in our recipes. Each
            ingredient is carefully selected to ensure the highest quality and authenticity in every dish. <br>
            Sustainability and health are at the heart of our sourcing practices. We exclusively use grass-fed meats and
            organic produce, supporting ethical farming practices and providing our customers with wholesome, nourishing
            meals. <br> In addition to our meat-based dishes, we offer delicious plant-based options, catering to
            diverse
            dietary preferences. Our homemade fries are crafted to perfection, providing a satisfying, crispy
            accompaniment to any meal.
          </p>
          <h1>At KEEBABI™️, we believe that dining is not just about food, but also about the overall experience. Our
            staff, renowned for their warmth and dedication, strives to make every visit an unforgettable event.<br> By
            embracing these values, KEEBABI™️ delivers an experience rooted in freshness, flavor, and
            tradition™️.</h1>

          <a href="/#menu">
            Get Started
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.293 0.792787C10.4805 0.605316 10.7348 0.5 11 0.5C11.2652 0.5 11.5195 0.605316 11.707 0.792787L15.707 4.79279C15.8945 4.98031 15.9998 5.23462 15.9998 5.49979C15.9998 5.76495 15.8945 6.01926 15.707 6.20679L11.707 10.2068C11.5184 10.3889 11.2658 10.4897 11.0036 10.4875C10.7414 10.4852 10.4906 10.38 10.3052 10.1946C10.1198 10.0092 10.0146 9.75838 10.0123 9.49619C10.01 9.23399 10.1108 8.98139 10.293 8.79279L12.586 6.49979H1C0.734784 6.49979 0.48043 6.39443 0.292893 6.20689C0.105357 6.01936 0 5.765 0 5.49979C0 5.23457 0.105357 4.98022 0.292893 4.79268C0.48043 4.60514 0.734784 4.49979 1 4.49979H12.586L10.293 2.20679C10.1055 2.01926 10.0002 1.76495 10.0002 1.49979C10.0002 1.23462 10.1055 0.980314 10.293 0.792787Z"
                    fill="white"/>
            </svg>
          </a>

        </div>
      </div>


      <div class="enjoy w-100 ">
        <video class="w-100" autoplay loop muted playsinline load>
          <source src="./our-beliefs-converted/Gif4.webm" type="video/webm">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="our-belifes-footer-side-bg h-100">
        <div class="our-belifes-footer-gif h-100">
          <img class="h-100" src="./our-beliefs-converted/Our-beliefs-5.webp" alt="">
          <a href="/#menu">Order Now
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.293 0.798646C10.4805 0.611175 10.7348 0.505859 11 0.505859C11.2652 0.505859 11.5195 0.611175 11.707 0.798646L15.707 4.79865C15.8945 4.98617 15.9998 5.24048 15.9998 5.50565C15.9998 5.77081 15.8945 6.02512 15.707 6.21265L11.707 10.2126C11.5184 10.3948 11.2658 10.4956 11.0036 10.4933C10.7414 10.491 10.4906 10.3859 10.3052 10.2005C10.1198 10.0151 10.0146 9.76424 10.0123 9.50205C10.01 9.23985 10.1108 8.98725 10.293 8.79865L12.586 6.50565H1C0.734784 6.50565 0.48043 6.40029 0.292893 6.21275C0.105357 6.02522 0 5.77086 0 5.50565C0 5.24043 0.105357 4.98608 0.292893 4.79854C0.48043 4.611 0.734784 4.50565 1 4.50565H12.586L10.293 2.21265C10.1055 2.02512 10.0002 1.77081 10.0002 1.50565C10.0002 1.24048 10.1055 0.986174 10.293 0.798646Z"
                    fill="white"/>
            </svg>
          </a>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: [],
};
</script>

<style>
.our-belifes {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.our-belifes-bg-one {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.our-belifes-header-bg {
  width: 50%;
}

.our-belifes-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

}

.our-belifes-text h1 {
  color: #1E1E1E;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 200% */
}

.our-belifes-icons-one {
  position: absolute;
  top: 29%;
  left: -9%;
}

.our-belifes-icons-two {
  position: absolute;
  top: 19%;
  left: 89%;


}

.our-belifes-icons-one {
  animation: leftupDown 4.6s infinite;
  position: absolute !important;
}

.our-belifes-icons-two {
  animation: rightupDown 6s infinite;
  position: absolute !important;
}

.our-belifes-content-left p, .our-belifes-commitment-right p {
  text-align: justify;
  line-height: inherit;
}

.our-belifes-content-left h1 {
  text-wrap: nowrap;
  color: #691209;
  font-size: 3rem;
}

@keyframes leftupDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }
}

@keyframes rightupDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }
}

.our-belifes-gif {
  width: 100%;
}

.our-belifes-gif img {
  width: 100%;
}

.our-belifes-commitment {
  position: relative;
  width: 100%;
  height: 95vh;
}

.our-belifes-commitment > img {
  position: absolute;
  top: -26px;
  z-index: 1;
  height: 100vh;
}


.ourbelifes-bottom-img {
  position: absolute;
  z-index: 1;
  width: 380px;
  left: 62px;
  top: 168px;
  animation: drop 5s infinite;
}

@keyframes drop {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }
}


.ourbelifes-top-img {
  position: absolute;
  z-index: 2;
  left: -6.5rem;

}


.our-belifes-twist {
  width: 100%;
  height: 100vh;
  position: relative;

}

.our-belifes-bg {
  width: 100%;
  height: 190vh;
  background: url(./Our-belifes-image/our-belifes-big-bg.png);
}

.our-belifes-content {
  padding: 80px;
  gap: 80px;
}

.our-belifes-gif-two {
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 200px;

}

.our-belifes-gif-two img {
  width: 1300px;
  height: 550px;
}

.plate-img {

  top: -43%;
  left: 24%;
}

.plate-img img {
  width: 700px;
  height: 560px;

}

.our-belifes-commitment-right {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  left: 46%;
  top: 10rem;
  text-align: justify;
}

.our-belifes-commitment-right h1 {
  font-size: 3rem;
  color: #691209;
  font-weight: 700;
}

.plate-img {
  position: relative;
  height: 1122px;
}

.plate-img img {
  position: -webkit-sticky;
  position: sticky;
  top: 361px;
}


.top-quality {
  width: 100%;
  height: 110vh;
  display: flex;
  align-items: end;
  position: relative;
  top: -6rem;
}

.top-quality::after {
  content: '';
  background: url(./Our-belifes-image/Our-belifes-twist-small-bg-two.svg);
  width: 100%;
  height: 100px;
  top: 4rem;
  position: absolute;
}

.top-quality::before {
  content: '';
  background: url(./Our-belifes-image/Our-belifes-twist-small-bg-three.svg);
  width: 100%;
  height: 100px;
  bottom: -98px;
  position: absolute;
}

.top-quality-bg {
  background: url(./Our-belifes-image/top-qulity.png);
  width: 100%;
  display: flex;
  align-items: end;
}

.top-quality-bg::after {
  content: '';
  background: url(./Our-belifes-image/top-qulity-plate.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 500px;
  top: 21%;
  left: 0;
  position: absolute;
  z-index: -1;
}

.top-quality-bg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-quality-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-quality-animation img {
  width: 100%;
}

.top-small-img {
  background: url(./Our-belifes-image/top-quality-small-img-one.png);
  width: 200px !important;
  height: 200px;
  position: absolute;
  animation: change 10s infinite;
}

@keyframes change {
  0% {
    background: url(./Our-belifes-image/top-quality-small-img-one.png);
  }
  20% {
    background: url(./Our-belifes-image/top-quality-small-img-two.png);
  }
  40% {
    background: url(./Our-belifes-image/top-quality-small-img-three.png);
  }
  60% {
    background: url(./Our-belifes-image/top-quality-small-img-four.png);
  }
  80% {
    background: url(./Our-belifes-image/top-quality-small-img-five.png);
  }
  100% {
    background: url(./Our-belifes-image/top-quality-small-img-six.png);
  }
}


.your-enjoyment {
  width: 100%;
  height: 133vh;
  background: url(./our-beliefs-converted/Our-beliefs-4.webp);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: center;
  text-align: center;
  padding-bottom: 100px;
}


.your-enjoyment-content {
  position: absolute;
  width: 775px;

}

.your-enjoyment-content h1 {
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 20px;
}

.your-enjoyment-content a {
  display: inline-block !important;
  text-decoration: none;
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  padding: 15px 60px;
  border-radius: 6px;
  background: #FF8B00;
}

.enjoy {

  position: relative;
  top: -30px;
  z-index: -1;


}

.our-belifes-footer-side-bg {

  width: 100%;
  height: 100vh;
  background: url(./Our-belifes-image/our-belifes-footer-side-bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative;
  top: -65px;
}

.our-belifes-footer-gif {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-belifes-footer-gif img {
  margin: auto;
  width: 100%;
  height: 100%;
}

.our-belifes-footer-gif a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%; /* 37.4px */
  border-radius: 6px;
  background: #691209;
  padding: 15px 60px;
  transform: translateY(-101px);
}


@media only screen and (max-width: 599px) {
  .our-belifes-commitment-right h1 {
    font-size: 2rem;
    color: #691209;
    font-weight: 700;
  }

  .our-belifes-content-left h1 {
    text-wrap: nowrap;
    color: #691209;
    font-size: 2rem;
  }

  .ourbelifes-top-img {
    position: absolute;
    z-index: 2;
    width: 250px;
    top: 4rem;
    left: -8rem;
  }

  .our-belifes-header-bg {
    width: 100%;
  }
  .top-quality::after {
    top: 0rem;
  }
  .top-quality-bg::after {
    top: 11%;
  }


  .our-belifes-bg {
    width: 100%;
    height: 170vh;
  }

  .ourbelifes-bottom-img {
    position: absolute;
    z-index: 1;
    width: 224px;
    left: 24px;
    top: 123px;
  }

  .top-quality-animation {
    overflow: hidden;
  }

  .top-quality-animation img {
    width: 375px;
  }

  .our-belifes-gif-two {
    margin-top: 180px;
  }


  .our-belifes-gif-two img {
    width: 20.438rem;
    height: 23.438rem;
  }

  .plate-img img {
    width: 250px !important;
    height: 266px !important;
    position: sticky;
    top: 527px;
  }

  .our-belifes-content-right > img {
    width: 330px;
  }

  .our-belifes-icons-two {
    width: 100px;
    position: absolute;
    top: 30%;
    left: 73%;
  }

  .our-belifes-icons-one {
    width: 200px;
    position: absolute;
    top: 47%;
    left: -7%;
  }

  .plate-img {
    height: 629px;
    top: -25%;
    left: 26%;
  }

  .your-enjoyment-content {
    position: absolute;
    width: 370px;
  }

  .our-belifes-footer-gif {
    height: 80% !important;
  }

  .your-enjoyment {
    height: 100vh;
  }

  .top-quality {
    height: 90vh;
  }

  .top-quality-bg {
    height: 54vh;
  }

  .our-belifes-commitment {
    width: 100%;
    height: 122vh;
  }

  .our-belifes-commitment > img {
    height: 132vh;
  }

  .ourbelifes-bottom-img {

    left: 24px;
    top: 108px;
  }

  .ourbelifes-top-img {

    top: 75px;
    left: -196px;
  }

  .our-belifes-content {
    flex-direction: column-reverse;
    gap: 30px;
    text-align: center;
    margin: 2rem;
  }

  .our-belifes-text {
    width: 65%;
  }

  .our-belifes-text h1 {
    color: #1E1E1E;
    text-align: justify;
    font-size: 15px;
  }

  .our-belifes-commitment-right {
    width: 100%;
    transform: translateY(470px);
    position: unset;
    z-index: 1;
    text-align: center;
    padding: 2rem 3rem;
  }

  .our-belifes-footer-gif a {
    transform: translateY(-43px);
  }


  .reward-best-deal-left img {
    height: 20rem;
    width: 20rem;
  }

  .our-belifes-footer-gif a, .your-enjoyment-content a {
    font-size: 14px;
    padding: 10px 15px;
    margin-top: 8px;
  }

  .our-belifes-gif-two {
    margin-top: 6px;
  }

  .your-enjoyment {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .our-belifes-commitment-right h1 {
    font-size: 2rem;
  }

  .ourbelifes-top-img {
    position: absolute;
    z-index: 2;
    height: 450px;
    top: 1.7rem !important;
    left: -7rem !important;
  }

  .our-belifes-header-bg {
    width: 100%;
  }

  .ourbelifes-bottom-img {
    position: absolute;
    z-index: 1;
    width: 224px;
    left: 24px;
    top: 258px;
  }

  .our-belifes-content {
    flex-direction: column-reverse;
    gap: 30px;
    text-align: center;
  }

  .our-belifes-commitment-right {
    width: 46%;
    left: 48%;
  }

  .our-belifes-gif-two img {
    width: 600px;
    height: 490px;
  }

  .plate-img img {
    width: 400px !important;
    height: 400px !important;
  }

  .our-belifes-content-right > img {
    width: 350px;
  }

  .our-belifes-icons-two {
    width: 200px;
    position: absolute;
    top: 19%;
    left: 83%;
  }

  .our-belifes-icons-one {
    width: 300px;
    position: absolute;
    top: 29%;
    left: -7%;
  }

  .plate-img {
    height: 840px;
    top: -25%;
    left: 26%;
  }

  .your-enjoyment-content {
    position: absolute;
    width: 590px;
  }

  .our-belifes-footer-gif {
    height: 80% !important;
  }

  .your-enjoyment {
    height: 100vh;
  }

  .top-quality {
    height: 105vh;
  }

  .top-quality-bg {
    height: 54vh;
  }

  .our-belifes-commitment {

    width: 100%;
    height: 68vh;
  }

  .our-belifes-commitment > img {
    height: 73vh;
  }

  .ourbelifes-bottom-img {

    left: 24px;
    top: 108px;
  }

  .ourbelifes-top-img {
    top: 75px;
    left: -196px;
  }

  .our-belifes-commitment-right {
    top: 3rem;
  }


}


@media only screen and (min-width: 992px) and (max-width: 1299px) {

  .ourbelifes-top-img {
    position: absolute;
    z-index: 2;
    left: -6.5rem;
    height: 550px;
    top: 5.5rem;
  }

  .ourbelifes-bottom-img {
    position: absolute;
    z-index: 1;
    width: 310px;
    left: 42px;
    top: 195px;
  }

  .our-belifes-commitment-right {
    left: 48%;
  }

  .our-belifes-gif-two img {
    width: 990px;
    height: 550px;
  }

  .plate-img img {
    width: 550px !important;
    height: 530px !important;
  }

  .our-belifes-content-right > img {
    width: 350px;
  }

  .our-belifes-icons-two {
    width: 200px;
    position: absolute;
    top: 19%;
    left: 83%;
  }

  .our-belifes-icons-one {
    width: 300px;
    position: absolute;
    top: 29%;
    left: -7%;
  }

}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {

}
</style>
