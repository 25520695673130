<template>
  <div>
    <div class="create-item-child-head ">
      <div class="create-item-child-img-modal">
        <img :src="parsedProduct.image" alt="" height="300" width="300">

      </div>
      <div class="create-item-child-details">
        <h1>{{ parsedProduct.title }}</h1>
        <p>{{ parsedProduct.description }}</p>

<!--        <div class="create-item-icon">-->
<!--          <span>⏰ 15 - 20 min</span>-->
<!--        </div>-->
      </div>
    </div>
    <div class="create-item-child-body">
      <div class="create-item-child-body-head">
        <h1>Make Your Own </h1>
        <div class="create-items-body-btn">
        <span v-for="(variant, index) in parsedProduct.variant"
            :key="variant.id"
            :class="{ 'active': selectedVariantIndex === index, 'variant-active': selectedVariantIndex === index, 'item-selected': isVariantItemSelected && selectedVariantIndex === index }"
            @click="selectVariant(index)">
            {{ variant.title }}
          </span>
        </div>
      </div>
      <div v-if="variantLimitReached" class="variant-limit-note">
        <p>You can only select up to {{ parsedProduct.variant[selectedVariantIndex].selectable_items }} items for this
          variant.</p>
      </div>

      <div class='create-item-menus row'>
        <div class="create-item-menu col-12 col-lg-5"
             v-for="item in parsedProduct.variant[selectedVariantIndex].items"
             :key="item.id"
             @click="selectItem(item)"
             :class="{ 'selected': item.selected }">
          <img :src="item.image" alt="" width="100" height="100">
          <div class="create-item-priceing-area">
            <h1 class="create-item-name">{{ item.title }}</h1>
            <div class="create-item-price-quantity">
<!--              <p>{{ item.calories }} Cal</p>-->
              <h1 v-if="item.price !== '0.00'">{{ item.price }}<sup>$</sup></h1>
            </div>

            <transition name="slide">
              <div class="item-options d-flex" v-if="item.showOptions">
                <div class="me-4" v-for="option in availableOptions(item)" :key="option.id">
                  <input type="radio" :id="'option-' + option.id" :value="option.title"
                         v-model="item.selectedOption" @click.stop @change="selectOption(item, option)">
                  <label :for="'option-' + option.id">{{ option.title }}</label>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <h6 style=" margin: 2rem 1rem; color: white; padding: 0.5rem 1rem; background: #5b140db0; width: fit-content;">NOTE:
      THE MENU IS SUBJECT TO CHANGE AT ANY TIME WITHOUT PRIOR NOTICE.</h6>
    <div class="d-flex buy_add position-sticky">
      <button class="btn w-100 buy_btn_1 text-nowrap" @click="addToCart(parsedProduct.id)"> Add to cart</button>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "OtherOrders",
  props: ['product'],
  data() {
    return {
      parsedProduct: this.product,
      selectedVariantIndex: 0,
      variantLimitReached: false,
      cart: [],
      errorMessage: ''
    };
  },
  // created() {
  //   const productKey = Object.keys(this.product)[0]; // Get the first key (in this case "9")
  //   this.parsedProduct = this.product[productKey]; // Set this.product to the value of that key
  //   console.log(this.parsedProduct);
  // },
  computed: {
    isVariantItemSelected() {
      return this.parsedProduct.variant[this.selectedVariantIndex].items.some(item => item.selected);
    },
    selectedItemsCount() {
      return this.parsedProduct.variant[this.selectedVariantIndex].items.filter(item => item.selected).length;
    }
  },
  methods: {
    initializeShowQuantity() {
      this.parsedProduct.variant.forEach(variant => {
        variant.items.forEach(item => {
          item.showQuantity = false;
          item.quantity = 1; // Initialize quantity for incrementable items
          item.selected = false;
          item.selectedOption = null;
          item.showOptions = false; // Initialize showOptions for sliding effect
        });
      });
    },
    selectVariant(index) {
      this.selectedVariantIndex = index;
      this.variantLimitReached = false;
    },
    selectItem(item) {
      const maxSelectableItems = this.parsedProduct.variant[this.selectedVariantIndex].selectable_items;
      const selectedCount = this.selectedItemsCount;

      if (maxSelectableItems === 0 || item.selected || selectedCount < maxSelectableItems) {
        item.selected = !item.selected;
        this.variantLimitReached = false;
      } else {
        this.variantLimitReached = true;
      }
    },
    selectOption(item, option) {
      item.selectedOption = option.title;
    },
    availableOptions(item) {
      return item.options.filter(option => option.availability);
    },
    getSelectedItems() {
      const selectedItems = {
        productId: this.parsedProduct.id,
        productTitle: this.parsedProduct.title,
        productImage: this.parsedProduct.image,
        productPrice: this.parsedProduct.price || '0.00',
        variants: {}
      };

      this.parsedProduct.variant.forEach(variant => {
        selectedItems.variants[variant.title] = variant.items.filter(item => item.selected).map(item => ({
          id: item.id,
          title: item.title,
          image: item.image,
          price: item.price || '0.00',
          calories: item.calories,
          nutrition_type: item.nutrition_type,
          quantity: item.quantity > 0 ? item.quantity : 1,
          selectedOptions: item.selectedOption
        }));
      });

      return selectedItems;
    },
    getTotalPriceAndCalories(productVariants) {
      let totalPrice = 0;
      let totalCalories = 0;

      productVariants.forEach(variant => {
        variant.forEach(item => {
          totalPrice += parseFloat(item.price) * item.quantity;
          totalCalories += parseFloat(item.calories) * item.quantity;
        });
      });

      if (this.selectedItems && this.selectedItems.productPrice) {
        totalPrice += parseFloat(this.selectedItems.productPrice);
      }

      console.log(totalPrice, totalCalories);
      return {totalPrice, totalCalories};
    },

    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    validateRequiredVariants() {
      for (let variant of this.parsedProduct.variant) {
        if (variant.required && !variant.items.some(item => item.selected)) {
          this.errorMessage = `Please select a ${variant.title}`;
          return false;
        }
      }
      this.errorMessage = '';
      return true;
    },
    addToCart() {
    if (this.validateRequiredVariants()) {
        const selectedItems = this.getSelectedItems();
        const { totalPrice, totalCalories } = this.getTotalPriceAndCalories(Object.values(selectedItems.variants));

        const csrfToken = this.getCookie('csrftoken');
        if (!csrfToken) {
            console.error('CSRF token not found!');
            return;
        }
        fetch(`./update-cart-salad/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken
            },
            body: JSON.stringify({
                product_title: selectedItems.productTitle,
                product: selectedItems.productId,
                total_price: totalPrice,
                total_cal: totalCalories,
                variants: selectedItems.variants
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            this.emitter.emit('add-to-cart', data);
            this.$toast.success('Item successfully added to cart!', {
                duration: 4000, // Optional: duration in milliseconds
                position: 'bottom'
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    } else {
        this.$toast.error(this.errorMessage, {
            duration: 5000, // Optional: duration in milliseconds
            position: 'top'
        });
    }
}

  }
}
</script>

<style scoped>

.create-item-menu img {
  position: relative;
}

.buy_add {
  bottom: 0px;
  background: #500c05;
  margin: 40px 0;
  align-items: center;
  justify-content: center
}

.buy_btn_1 {
  border-right: 1px solid white;
  border-radius: unset;
}

.buy_add button {
  display: inline-block;
  color: white !important;
  padding: 0.5rem 0;
  font-weight: 700;
  font-size: 25px;
  border: none;
  justify-content: center;
}

.buy_add button:hover {
  color: white;
}

.selected {
  border: 2px solid #ff6600 !important; /* Example border color */
}

.items-seleted {
  background: limegreen !important;
  color: white !important;
}

.variant-active {
  background: #691209 !important;
  color: white !important;
}

.create-item-child {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 0;

}

.create-item-child-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 2rem;
}

.create-item-child-img-modal img {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.create-item-child-img-modal {
  filter: drop-shadow(8px 8px 10px gray);
}

.create-item-child-details {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.create-item-child-details h1 {
  margin-top: 2rem;
  text-align: start;
  color: #691209;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */

}

.create-item-child-details p {
  text-align: start;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  margin-bottom: 1rem;
  /*border-bottom: 2px solid #691209;*/
}

.create-item-icon {
  display: flex;
  gap: 22px;
  align-items: center;
  margin-bottom: 40px;
}

.create-item-icon span {
  color: #4A4A4A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */

}

.create-item-price-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-item-price-area h1 {
  color: #691209;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px; /* 100% */
  margin-bottom: 0;
}

.create-item-price-area h1 sup {
  color: #691209;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;

}

.create-item-quantity {
  display: flex;
  align-items: center;
  gap: 28px;
}

.create-item-quantity img {
  width: 40px;
  height: 40px;
}

.create-item-quantity span {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 62.5% */
}

.create-item-child-body-head h1 {
  text-align: start;
  color: #691209;
  margin-top: 2.5rem;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
}

.create-items-body-btn {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 2.5rem;
  text-wrap: nowrap;
}

.create-items-body-btn span {
  padding: 14px 28px;
  border: 2px solid #E7EAF3;
  background: #FFF;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  cursor: pointer;
  transition: 0.5s;
}

.create-items-body-btn span:hover {
  background: #F99F00;
  color: white;
}

.create-item-menus {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  margin: auto;
}

.create-item-menu img {
  width: 6.25rem;
  height: 6.25rem;
}

.create-item-menu {
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid #E7EAF3;
  background: #FFF;
  justify-content: space-between;
  transition: .3s;
}

.create-item-menu:hover {
  border-radius: 20px;
  border: 2px solid #691209;
  background: #FFF;
  box-shadow: 0px 27px 80px 0px rgba(201, 203, 208, 0.27);
}

.create-item-menu .create-item-name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.create-item-price-quantity {
  display: flex;
  align-items: center;
  gap: 25px;
}

.create-item-price-quantity p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
  text-transform: capitalize;
  margin-bottom: 0;
}

.create-item-price-quantity h1 {
  color: #1E1E1E;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 222.222% */
  margin: 0;
}

.create-item-price-quantity sup {
  color: #1E1E1E;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.item-child-quantity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.item-child-quantity img {
  width: 20px;
  height: 20px;
}

.item-child-quantity span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

@media only screen and (max-width: 599px) {
  .create-item-child-img-modal {
    width: 200px;
    height: 200px;
  }

  .create-item-child-img-modal img {
    width: 100%;
    height: 100%;

  }

  .create-item-child {
    padding: 80px 20px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .create-item-menus {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .create-items-body-btn {
    gap: 10px;
    overflow-x: scroll;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menu > img {
    width: 90px;
    height: 90px;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 15px;
  }

  .item-child-quantity {
    gap: 15px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-price-quantity {
    gap: 10px;
  }

  .create-item-price-quantity h1 {
    font-size: 15px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }

  .create-item-menu .create-item-name {
    font-size: 18px;
  }

  .create-item-price-quantity p {
    font-size: 14px;
  }

  .item-child-quantity img {
    width: 15px;
    height: 15px;
  }

  .item-child-quantity span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .create-item-child-img-modal {
    width: 150px;
    height: 150px;
  }

  .create-item-child-img-modal img {
    width: 100%;
    height: 100%;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  /* .create-item-menus {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
  } */
  .create-items-body-btn {
    gap: 10px;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 20px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .create-item-icon {
    gap: 17px;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-items-body-btn {
    gap: 16px;
  }

  .create-items-body-btn span {
    padding: 14px 18px;
    font-size: 1.5rem;
  }

  .create-item-menus {
    gap: 30px;
  }

  .create-item-menu {
    padding: 15px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .create-items-body-btn {
    gap: 30px;
  }
}

</style>